<template>
  <hcc-modal
    :title="title"
    class="shop"
    :width="600"
    name="ecommerce-form"
    @confirm="createEcommerce"
    @opened="focusBuildModal"
    :confirmDisableButton="!validForm"
    :adaptive="true"
    ref="hccModal"
  >
    <div class="user__property">
      <hcc-input
        :placeholder="$t('shop.ecommerce.name')"
        v-model="newEcommerce.nombre"
        @blur="$v.newEcommerce.nombre.$touch()"
        :error="$v.newEcommerce.nombre.$error"
        :errorMessage="
          $t('shop.errors.required', { field: $t('shop.ecommerce.name') })
        "
        :label="$t('shop.ecommerce.name')"
        ref="hccInput"
      />

      <hcc-input
        :placeholder="$t('shop.ecommerce.url')"
        v-model="newEcommerce.descripcion"
        @blur="$v.newEcommerce.descripcion.$touch()"
        :error="$v.newEcommerce.descripcion.$error"
        :errorMessage="
          $t('shop.errors.required', { field: $t('shop.ecommerce.url') })
        "
        :label="$t('shop.ecommerce.url')"
      />
      <hcc-button
        v-if="newEcommerce.id"
        color="alert"
        @click="removeStore"
      >{{$t('shop.ecommerce.delete')}}</hcc-button>
    </div>
    <hcc-confirmation />
  </hcc-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import HccModal from '@/components/shared/HccModal/index.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';

export default {
  components: {
    HccModal,
    HccInput,
    HccButton,
    HccConfirmation,
  },
  props: {
    dataEcommerce: {},
  },
  data() {
    return {
      newEcommerce: {
        categoria: {
          id: 'Ecommerce',
          value: 'Ecommerce',
        },
      },
    };
  },
  validations: {
    newEcommerce: {
      nombre: { required },
      descripcion: { required },
    },
  },
  watch: {
    dataEcommerce(newValue) {
      this.newEcommerce = newValue;
    },
  },
  methods: {
    focusBuildModal() {
      this.$refs.hccInput.focus();
    },
    createEcommerce() {
      this.$emit('onCreateEcommerce', this.newEcommerce);
    },
    removeStore() {
      this.$modal.show('confirmation', {
        title: this.$t('shop.ecommerce.remove-confirm'),
        description: this.$t('shop.ecommerce.confirm-remove-text'),
        variant: 'default',
        confirm: () => {
          this.$emit('onRemove', this.newEcommerce.id);
          this.$modal.hide('confirmation');
        },
      });
    },
  },
  computed: {
    validForm() {
      return !this.$v.$invalid;
    },
    title() {
      return this.newEcommerce.nombre ? this.$t('shop.ecommerce.title-edit') : this.$t('shop.ecommerce.title-add');
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_user.scss";
</style>
