<template>
  <hcc-modal
    :title="$t('shop.create')"
    customClassBody="shop"
    :width="600"
    name="shop-form"
    @confirm="createStore"
    @opened="focusBuildModal"
    :confirmDisableButton="!validForm"
    :adaptive="true"
  >
    <div class="user__property">
      <hcc-input
        :placeholder="$t('shop.name')"
        v-model="newStore.nombre"
        @blur="$v.newStore.nombre.$touch()"
        :error="$v.newStore.nombre.$error"
        :errorMessage="$t('shop.errors.required', { field: $t('shop.name') })"
        :label="$t('shop.name')"
        ref="hccInput"
      />
      <hcc-input-places
        :placeholder="$t('shop.address')"
        v-model="newStore.direccion"
        @blur="$v.newStore.direccion.$touch()"
        :error="$v.newStore.direccion.$error"
        :errorMessage="
          $t('shop.errors.required', { field: $t('shop.address') })
        "
        :label="$t('shop.address')"
        @place_selected="changeByAddress"
      />
      <hcc-input
        :placeholder="$t('shop.bussiness_name')"
        v-model="newStore.nombreLegal"
        @blur="$v.newStore.nombreLegal.$touch()"
        :error="$v.newStore.nombreLegal.$error"
        :errorMessage="
          $t('shop.errors.required', { field: $t('shop.bussiness_name') })
        "
        :label="$t('shop.bussiness_name')"
      />
      <hcc-select
        :label="$t('shop.category')"
        custom-class="select"
        v-model="newStore.categoria"
        :placeholder="$t('shop.product.select-option')"
        :options="categories"
        :error="$v.newStore.categoria.$error"
        :errorMessage="
          $t('shop.errors.required', { field: $t('shop.category') })
        "
        option-label="value"
        track-by="id"
      />
      <template v-if="value">
        <hcc-textarea
          name="description"
          :placeholder="$t('shop.description')"
          v-model="newStore.descripcion"
          :label="$t('shop.description')"
        />
        <div class="multimedia__drop">
          <vue-file-agent
            ref="dropZone"
            :multiple="false"
            :helpText="$t('shop.image')"
            :maxFiles="1"
            :meta="true"
            :compact="true"
            theme="list"
            accept="image/*"
            :maxSize="maxSize"
            @select="selectImage"
            v-model="image"
          >
          </vue-file-agent>
        </div>
      </template>
    </div>
  </hcc-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'StoreForm',
  components: {
    HccModal: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccModal/index.vue'),
    HccInput: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccInput/index.vue'),
    HccSelect: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccSelect/index.vue'),
    HccTextarea: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccTextarea/index.vue'),
    HccInputPlaces: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccInputPlaces/index.vue'),
  },
  props: {
    value: {},
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      newStore: {},
      google: null,
      showLogo: false,
      autocomplete: null,
      image: [],
    };
  },
  validations: {
    newStore: {
      nombre: { required },
      direccion: { required },
      nombreLegal: { required },
      categoria: { required },
    },
  },
  computed: {
    validForm() {
      return !this.$v.$invalid;
    },
    maxSize() {
      return process.env.VUE_APP_UPLOAD_MAX_FILESIZE;
    },
    categories() {
      return [
        { id: 'Restaurante', value: 'Restaurante' },
        { id: 'Tienda', value: 'Tienda' },
      ];
    },
  },
  methods: {
    focusBuildModal() {
      this.$refs.hccInput.focus();
    },
    createStore() {
      this.$emit('save', this.newStore);
    },
    changeByAddress(address) {
      const {
        place_id: placeId,
        formatted_address: formattedAddress,
        address_components: addressComponents,
      } = address;

      if (!placeId) {
        this.newStore.direccion = '';
        this.newStore.location = {};
        this.$v.newStore.direccion.$touch();
        return;
      }

      this.newStore.direccion = formattedAddress;
      this.newStore.location = {
        place_id: placeId,
        formatted_address: formattedAddress,
        address_components: addressComponents,
      };
      this.$v.newStore.direccion.$touch();
    },
    selectImage(files) {
      const { file } = files[0];
      this.newStore.file = file;
    },
  },
  watch: {
    value(newState) {
      if (newState) {
        this.newStore = { ...newState };
        this.image = [];
        if (this.newStore.imageURL) {
          this.image.push({
            name: `${this.newStore.nombre}.${this.newStore.ext}`,
            size: this.newStore.size,
            type: 'image/*',
            ext: this.newStore.ext,
            url: this.newStore.imageURL,
          });
        }
      } else {
        this.newStore = {
          nombre: '',
          direccion: '',
          nombreLegal: '',
          categoria: null,
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_user.scss";
</style>
