<template>
  <div class="loader-container">
    <overlay-loader :loading="loading" />
    <section class="main-content">
      <div v-show="isOwner" class="grid-x align-right margin-bottom--20">
        <div class="cell shrink">
          <button-filter
            v-bind:display="enabledFilters"
            v-on:change="onFiltersChanged"
          />
        </div>
      </div>
      <div class="grid-x grid-margin-x grid-margin-y" v-if="!isLoading">
        <hcc-table
          class="cell small-12"
          :enableSearch="true"
          :rowsPerPage="10"
          :actualPage="actualPage"
          v-on="!hideButtonEcommerce ? { add: addEcommerce } : {}"
          :buttonText="$t('shop.ecommerce.title-add')"
          @pageChanged="changePage"
          :rows="stores"
          :columns="columns"
          @edit="editStore"
          @preview="showStore"
          @calendar="showCalendar"
        />
      </div>
    </section>
    <store-form v-model="currentStore" v-on:save="saveNewData" />
    <ecommerce-form
      :dataEcommerce="currentStore"
      @onCreateEcommerce="CreateEcommerce"
      @onRemove="removeEcommerce"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import { getImageUrlInformation } from '@/utils/helper';
import StoreForm from '@/components/shop/StoreForm.vue';
import EcommerceForm from '@/components/shop/EcommerceForm.vue';
import HccTable from '@/components/shared/HccTable/index.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import editCampaignMutation from '@/graphql/mutations/editCampaign.gql';
import campaignsByCompanyIdQuery from '@/graphql/queries/campaigns/byCompanyId.gql';

export default {
  components: {
    HccTable,
    StoreForm,
    OverlayLoader,
    ButtonFilter: () => import('@/components/ButtonFilter.vue'),
    EcommerceForm,
  },
  data() {
    return {
      actualPage: 1,
      loading: true,
      filters: null,
      enabledFilters: ['company'],
      currentStore: {},
    };
  },
  activated() {
    if (this.filters !== null || !this.isOwner) {
      this.init();
    }
  },
  computed: {
    ...mapState({
      user: state => state.shared.user,
      stores: state => state.shop.items,
    }),
    ...mapGetters({
      isOwner: 'shared/isOwner',
      isSuper: 'shared/isSuper',
      campaigns: 'setting/getCampaigns',
    }),
    isLoading() {
      return this.loading || this.$apollo.loading;
    },
    columns() {
      return [{
        label: this.$t('shop.name'),
        field: 'nombre',
      }, {
        label: this.$t('shop.description'),
        field: 'descripcion',
      }];
    },
    currentCompanyId() {
      return this.isOwner ? this.filterCompany : this.userCompany.id;
    },
    userCompany() {
      return JSON.parse(localStorage.currentCompany);
    },
    filterCompany() {
      return this.filters ? this.filters.companyId : 0;
    },
    hideButtonEcommerce() {
      return this.stores.some(({ categoria }) => categoria?.value === 'Ecommerce');
    },
  },
  methods: {
    ...mapActions({
      saveStore: 'shop/save',
      getMyStores: 'shop/getStoreOfUser',
      clearMyStore: 'shop/clearStoreOfUser',
      saveEcommerce: 'shop/saveEcommerce',
      removeStore: 'shop/remove',
      updateCampaigns: 'setting/updateCampaign',
    }),
    addEcommerce() {
      this.currentStore = {};
      this.$modal.show('ecommerce-form');
    },
    async CreateEcommerce(data) {
      const dataEcommerce = {
        ...data,
        categoria: {
          id: 'Ecommerce',
          value: 'Ecommerce',
        },
        heydata: {
          company: this.currentCompanyId,
        },
      };
      await this.saveEcommerce(dataEcommerce);
      this.init();
    },
    onFiltersChanged(filters) {
      this.filters = filters;
    },
    getFilterStore(campaigns = []) {
      if (this.isSuper || this.isOwner) {
        return [
          'heydata.company',
          '==',
          this.currentCompanyId,
        ];
      }

      return [
        'heydata.campaign',
        'in',
        campaigns.map(({ id }) => id),
      ];
    },
    async init() {
      this.loading = true;
      const { data: { campaignsByCompanyId } } = await this.$apollo.query({
        query: gql`${campaignsByCompanyIdQuery}`,
        variables: {
          companyId: this.currentCompanyId,
        },
        fetchPolicy: 'no-cache',
      });
      if (campaignsByCompanyId && campaignsByCompanyId.length > 0) {
        await this.getMyStores(this.getFilterStore(campaignsByCompanyId));
      } else {
        this.clearMyStore();
      }
      this.loading = false;
    },
    async saveNewData(storeData) {
      this.loading = true;
      await this.saveStore(storeData);
      if (this.campaigns.length > 0) {
        await this.getMyStores(this.getFilterStore(this.campaigns));
      }
      const { data: campaign } = await this.$apollo.mutate({
        mutation: editCampaignMutation,
        variables: {
          id: storeData.id,
          name: storeData.nombre,
        },
      });
      this.updateCampaigns(campaign.editCampaign);
      this.loading = false;
    },
    async editStore(item) {
      const dataImage = await getImageUrlInformation(item.imageURL);
      this.currentStore = { ...item, ...dataImage, size: dataImage.size };
      if (this.currentStore.categoria?.value === 'Ecommerce') {
        this.$modal.show('ecommerce-form');
      } else {
        this.$modal.show('shop-form');
      }
    },
    changePage() {

    },
    showStore({ id, categoria, ...item }) {
      if (categoria.value === 'Ecommerce') {
        this.currentStore = { ...item, id, categoria };
        this.$modal.show('ecommerce-form');
      } else {
        this.$router.push({ name: 'store.admin.sellia', params: { id } });
      }
    },
    showCalendar({ id }) {
      window.open(`${process.env.VUE_APP_RESERVATIONS_URL}/calendar/${id}`, '_blank');
    },
    async removeEcommerce(id) {
      this.loading = true;
      await this.removeStore(id);
      this.$modal.hide('ecommerce-form');
      this.loading = false;
    },
  },
  watch: {
    filters(newValue, oldValue) {
      if (newValue !== null && (oldValue === null || newValue.companyId !== oldValue.companyId)) {
        this.init();
      }
    },
  },
};
</script>
